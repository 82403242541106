import { render, staticRenderFns } from "./NotAccessible.vue?vue&type=template&id=7fa4b973&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Jenkins_Home\\workspace\\liveinfo3-front-user-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7fa4b973')) {
      api.createRecord('7fa4b973', component.options)
    } else {
      api.reload('7fa4b973', component.options)
    }
    module.hot.accept("./NotAccessible.vue?vue&type=template&id=7fa4b973&", function () {
      api.rerender('7fa4b973', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/miscellaneous/NotAccessible.vue"
export default component.exports