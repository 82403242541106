import { render, staticRenderFns } from "./ModalClient.vue?vue&type=template&id=65a5aad4&"
import script from "./ModalClient.vue?vue&type=script&lang=js&"
export * from "./ModalClient.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Jenkins_Home\\workspace\\liveinfo3-front-user-dev\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65a5aad4')) {
      api.createRecord('65a5aad4', component.options)
    } else {
      api.reload('65a5aad4', component.options)
    }
    module.hot.accept("./ModalClient.vue?vue&type=template&id=65a5aad4&", function () {
      api.rerender('65a5aad4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/webseminar/calendar/ModalClient.vue"
export default component.exports