<template>
  <section
    class="comHeader"
    :class="{ 'main-type': wrapType === 'main', 'sub-type': wrapType === 'sub' }"
  >
    <header class="common-info">
      <h1><a
        title="홈"
        @click="link('app-main')"
      >LOGO</a></h1>
      <div
        v-if="isLogin"
        class="user-info"
        aria-label="로그인성공"
      >
        <p class="welcome-txt">
          <span>{{ userName }}</span> 선생님 환영합니다.
        </p>

        <div class="user-point">
          <span class="label">보유포인트</span>
          <span class="point-num"><a @click="link('app-point-mall-main')">{{ userPoint | $numberFormatter }}</a></span>
        </div>

        <div class="user-menu">
          <ul>
            <li><a
              class="btn-myPage"
              title="MY Page"
              @click="link('app-mypage-profile')"
            >MY</a></li>
            <li><a
              id="btnAlam"
              class="btn-alarm"
              title="알림"
              @click.prevent="openPopupAlarm"
            >알림<span class="num">{{ notificationCount > 99 ? '99+' : notificationCount }}</span></a></li>
            <li><a
              id="btnMonthStatus"
              class="btn-month-status"
              title="이번 달 참여현황"
              @click.prevent="openPopupMonthAward"
            >이번 달 참여현황</a></li>
            <li><a
              class="btn-1-question"
              title="1:1 문의"
              @click="link('app-mypage-askHistory')"
            >1:1 문의</a></li>
            <li><a
              class="btn-logout"
              title="로그아웃"
              @click="logout"
            >로그아웃</a></li>
          </ul>

          <!-- header layer all -->
          <div class="global-common-layer">
            <popup-alarm
              :show-modal="showPopupAlarm"
              @close="closePopupAlarm"
              @count="fetchNotificationCount"
            />
            <popup-month-award
              :show-modal="showPopupMonthAward"
              @close="closePopupMonthAward"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!isLogin"
        class="user-info"
        aria-label="로그아웃"
      >
        <div class="user-login">
          <ul>
            <li><a
              class="btn-login"
              @click="link('login-main')"
            >로그인</a></li>
          </ul>
        </div>
      </div>
    </header>
    <nav class="common-menu">
      <ul>
        <li :class="{ 'active': routeName === 'app-medical-trend-main' }">
          <a
            title="메디컬 트렌드"
            @click="link('app-medical-trend-main')"
          >메디컬 트렌드</a></li>
        <li :class="{ 'active': routeName === 'app-druginfo-main' }">
          <a
            title="약품 정보"
            @click="link('app-druginfo-main')"
          >약품 정보</a></li>
        <li :class="{ 'active': routeName === 'app-webseminar-client' || routeName === 'app-webseminar-kma' || routeName === 'app-webseminar-my' }">
          <a
            title="웹 심포지엄"
            @click="link('app-webseminar-client')"
          >웹 심포지엄</a></li>
        <li :class="{ 'active': routeName === 'app-survey-main' }">
          <a
            title="설문 조사"
            @click="link('app-survey-main')"
          >설문 조사</a></li>
        <li :class="{ 'active': routeName === 'app-event-main' }">
          <a
            title="이벤트"
            @click="link('app-event-main')"
          ><i class="event-badge-2501" />이벤트<span class="event-badge-new">NEW</span></a></li>
        <!-- <a
            title="이벤트"
            @click="link('app-event-main')"
          >이벤트</a></li> -->
        <li :class="{ 'active': routeName === 'app-notice-main' }">
          <a
            title="공지사항"
            @click="link('app-notice-main')"
          >공지사항</a> </li>
        <li :class="{ 'active': routeName === 'app-point-mall-main' }">
          <a
            title="포인트몰"
            @click="link('app-point-mall-main')"
          >포인트몰</a> </li>
      </ul>
    </nav>
    <alert-dialog :options="alertProps" />
  </section>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { liveInfoEventHandler } from '@/core/global/global-liveinfo'
import { clearUserData, getUserData, isUserLoggedIn } from '@/auth/utils'
import eventBus from '@/libs/event/event-bus'
import { setStatistics } from '@/common/statistics/service'
import { getPopupOption } from '@/common/popup/service'

import PopupAlarm from '@/views/apps/popup/PopupAlarm.vue'
import PopupMonthAward from '@/views/apps/popup/PopupMonthAward.vue'

export default {
  components: {
    PopupAlarm,
    PopupMonthAward,
  },
  data() {
    return {
      isLogin: false,
      wrapType: '',
      routeName: '',
      showPopupAlarm: false,
      showPopupMonthAward: false,
      notificationCount: 0,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
      userPoint: 'account/getPoint',
      infoSetting: 'infoData/getInfoSetting',
    }),
  },
  watch: {
    $route(to) {
      this.wrapType = to.meta.wrapType
      this.routeName = to.name

      // route 이동 시 열려있던 팝업 닫음
      this.closePopups()

      if (this.userName !== null) this.fetchNotificationCount()
    },
    userName(val) {
      if (val === null) this.isLogin = false
      else {
        this.isLogin = true
        this.fetchNotificationCount()
      }
    },
    infoSetting() {
      if (this.infoSetting) getPopupOption()
    }
  },
  async created() {
    this.wrapType = this.$route.meta.wrapType
    this.routeName = this.$route.name

    if (this.userName !== null) {
      this.isLogin = true

      this.fetchNotificationCount()
    } else {
      await this.checkLogin()
    }

    if (this.infoSetting) getPopupOption()
  },
  mounted() {
    eventBus.$on('logout', () => {
      this.logout()
    })

    eventBus.$on('notifyCount', () => {
      this.fetchNotificationCount()
    })

    eventBus.$on(liveInfoEventHandler, async payload => {
      switch (payload.funcName) {
        case 'InjectRefreshToken':
          // refreshToken 값 존재 여부와 관계없이 호출 된 경우 true
          this.$store.commit('infoData/setRefreshTokenSetting', true)

          if (payload.p.refreshToken) {
            useJwt.setRefreshToken(payload.p.refreshToken)
            await this.checkLogin()
          }
          break

        default:
          break
      }
    })
  },
  methods: {
    async checkLogin() {
      this.isLogin = await isUserLoggedIn()

      if (this.isLogin) {
        const user = getUserData()

        this.$store.commit('account/setName', user.userName)
        this.$store.commit('account/setPoint', user.userPoint)
      }
    },
    fetchNotificationCount() {
      if (this.userName) {
        axios.get('/fu/notification/count')
          .then(rs => {
            this.notificationCount = rs.data
          })
          .catch(() => {
          })
      }
    },
    openPopupAlarm() {
      this.closePopups()

      this.showPopupAlarm = true
    },
    closePopupAlarm() {
      this.showPopupAlarm = false
    },
    openPopupMonthAward() {
      this.closePopups()

      this.showPopupMonthAward = true
    },
    closePopupMonthAward() {
      this.showPopupMonthAward = false
    },
    closePopups() {
      this.showPopupAlarm = false
      this.showPopupMonthAward = false
    },
    link(route) {
      let entry
      switch (route) {
        case 'app-main':
          entry = 200; break
        case 'app-medical-trend-main':
          entry = 201; break
        case 'app-druginfo-main':
          entry = 202; break
        case 'app-webseminar-client':
          entry = 203; break
        case 'app-survey-main':
          entry = 204; break
        case 'app-event-main':
          entry = 205; break
        case 'app-notice-main':
          entry = 206; break
        case 'app-point-mall-main':
          entry = 207; break
        default:
          entry = -1
      }

      if (entry > 0) setStatistics(entry, 0) // 메뉴 진입 통계

      this.$router.push({ name: route }).catch(() => {})
      // if (this.routeName !== route) {
      //   this.$router.push({ name: route }).catch(() => {})
      // } else {
      //   this.$router.go()
      // }
    },
    logout() {
      clearUserData()
      setStatistics(102) // 102: 로그아웃 성공

      this.isLogin = false
    },
  },
}
</script>
