<template>
  <div data-label="KMA 연수 교육">
    <div class="period-calendar-wrap">
      <div class="text-wrap calendar-type">
        <date-picker
          v-model="searchDateFr"
          :lang="datePicker.lang"
          :disabled-date="dislabedDate"
          value-type="format"
          @pick="fetchKmaSeminars()"
        />
      </div>
      <label class="bu-wave" />
      <div class="text-wrap calendar-type">
        <date-picker
          v-model="searchDateTo"
          :lang="datePicker.lang"
          :disabled-date="dislabedDate"
          value-type="format"
          @pick="fetchKmaSeminars()"
        />
      </div>

      <div class="check-wrap">
        <input
          id="essentialView"
          v-model="isEssential"
          type="checkbox"
          @change="fetchKmaSeminars()"
        >
        <label for="essentialView">필수 교육만 보기</label>
      </div>

      <div class="right-col">
        <div class="select-wrap">
          <select v-model="searchType">
            <option value="Title">
              교육명
            </option>
            <option value="Organization">
              기관명
            </option>
          </select>
        </div>

        <div class="text-wrap btn-in">
          <input
            v-model="searchKeyword"
            type="text"
            @keyup.enter="fetchKmaSeminars()"
          >
          <button
            class="btn-search"
            @click="fetchKmaSeminars()"
          >
            검색
          </button>
        </div>
      </div>
    </div>

    <!-- 리스트 -->
    <div class="tb-list-type">
      <table>
        <colgroup>
          <col style="width: 200px">
          <col style="width: 135px">
          <col style="width: 336px">
          <col style="width: 115px">
          <col style="width: 45px">
          <col style="width: 120px">
        </colgroup>
        <thead>
          <tr>
            <th>일시</th>
            <th>기관명</th>
            <th>교육명</th>
            <th>장소</th>
            <th>평점</th>
            <th>MY세미나 등록</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item) in list"
            :key="item.kmaSeminarId"
          >
            <td>{{ getStartDateTime(item.startDate, item.startTime, item.endTime) }}</td>
            <td>{{ item.organization }}</td>
            <td>
              <p
                class="ellipsis-txt"
                style="text-decoration: underline; cursor: pointer;"
                @click.prevent="linkKmaSeminar(item)"
              >
                {{ item.title }}
              </p>
            </td>
            <td>
              <p class="ellipsis-txt">
                {{ item.place }}
              </p>
            </td>
            <td>{{ item.gradepoint.split(' ')[1] }}</td>
            <td>
              <button
                v-if="item.isReserved"
                class="btn-registering cancel-type"
                @click="cancelMySeminar(item.kmaSeminarId)"
              >
                등록취소
              </button>
              <button
                v-else
                class="btn-registering"
                @click="registMySeminar(item.kmaSeminarId)"
              >
                등록하기
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      :page="currentPage"
      :records="totalRecord"
      :per="10"
      @paging="pageUpdate"
    />

    <kma-seminar-guide v-if="guidePopup" />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import Pagination from '@/components/controls/Pagination.vue'

import DatePicker from 'vue2-datepicker'
import '@/libs/vue2-datepicker/calendar-vue.css'
import { locale } from '@/libs/vue2-datepicker/lang/ko-kr'
import { mapGetters } from 'vuex'
import { callExternalApi } from '@/core/global/global-liveinfo'

import KmaSeminarGuide from '../guide/KmaSeminarGuide.vue'

export default {
  components: {
    DatePicker,
    Pagination,
    KmaSeminarGuide
  },
  data() {
    return {
      list: [],
      currentPage: 1,
      pageCount: 10,
      totalRecord: 0,
      searchType: 'Title',
      searchKeyword: '',
      searchDateFr: dayjs().format('YYYY-MM-DD'),
      searchDateTo: dayjs().add(2, 'month').format('YYYY-MM-DD'),
      isEssential: false,
      today: dayjs().format('YYYY-MM-DD'),
      datePicker: {
        lang: locale(),
      },
      seminarType: 2,
      guidePopup: false
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName() {
      this.fetchKmaSeminars(this.currentPage)
    }
  },
  created() {
    this.fetchKmaSeminars()
  },
  mounted() {
    // setTimeout(() => {
    //   if (JSON.parse(localStorage.getItem('popup')).kmaGuide === false) this.guidePopup = true
    // }, 100)
  },
  methods: {
    dislabedDate(date) {
      // 운영, 개발여부 확인하여 이전날짜 조회 허용
      if (process.env.NODE_ENV === 'development') {
        return false
      }
      return dayjs(date).format('YYYY-MM-DD') < this.today
    },
    fetchKmaSeminars(page = 1) {
      if (this.searchDateFr > this.searchDateTo) {
        this.$parent.showAlertDialog("시작일은 종료일보다 클 수 없습니다.")

        return
      }

      this.currentPage = page

      axios.post("/fu/webseminar/kma", {
        search: {
          searchType: this.searchType,
          searchKeyword: this.searchKeyword,
          searchDateFr: this.searchDateFr,
          searchDateTo: this.searchDateTo,
          isEssential: this.isEssential,
        },
        pagination: {
          number: this.currentPage,
          count: this.pageCount,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          this.list = items
          this.totalRecord = totalRecord
        })
        .catch(() => {
          this.$parent.showAlertDialog('세미나 목록 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    pageUpdate(page) {
      this.fetchKmaSeminars(page)
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-webseminar-kma' } })
    },
    registMySeminar(seminarId) {
      if (this.userName === null) this.redirectLogin()
      else {
        axios.post('/fu/webseminar/my', {
          webSeminarId: seminarId,
          seminarType: this.seminarType
        })
          .then(() => {
            this.fetchKmaSeminars(this.currentPage)
          })
          .catch(() => {
            this.$parent.showAlertDialog('MY 세미나 저장에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
          })
      }
    },
    cancelMySeminar(seminarId) {
      this.$parent.showConfirmDialog('해당 일정을 MY 세미나에서 삭제하시겠습니까?', confirm => {
        if (confirm) {
          axios.delete('/fu/webseminar/my', {
            data: {
              webSeminarId: seminarId,
              seminarType: this.seminarType
            }
          })
            .then(() => {
              this.fetchKmaSeminars(this.currentPage)
            })
            .catch(() => {
              this.$parent.showAlertDialog('MY 세미나 삭제에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
            })
        }
      })
    },

    linkKmaSeminar(item) {
      const eduKmaUrl = 'https://edu.kma.org/edu/schedule_view'

      callExternalApi("LinkUrl", `${eduKmaUrl}?eduidx=${item.kmaSeminarId}`)
    },

    getStartDateTime(starDate, startTime, endTime) {
      const dayOfWeek = dayjs(starDate).format('d')
      const date = dayjs(starDate).format('YYYY-MM-DD')

      if (startTime && endTime) {
        return `${date} (${this.getDayOfWeekName(dayOfWeek)}) ${startTime} ~ ${endTime}`
      }

      return `${date} (${this.getDayOfWeekName(dayOfWeek)})`
    },
    getDayOfWeekName(dayOfWeek) {
      if (dayOfWeek === '0') { return '일' }
      if (dayOfWeek === '1') { return '월' }
      if (dayOfWeek === '2') { return '화' }
      if (dayOfWeek === '3') { return '수' }
      if (dayOfWeek === '4') { return '목' }
      if (dayOfWeek === '5') { return '금' }
      if (dayOfWeek === '6') { return '토' }
      return ''
    },
  },
}
</script>
